import React from 'react'
import './Hero.css'
import Header from './Header'
import './Header.css'
import hero_image from '../assets/hero_image1.png'
import hero_image_back from '../assets/hero_image_back_1.png'
import {motion} from 'framer-motion'


const Hero = () => {

  const transition={type:'spring', duration:3}
  return (
<div className="hero" id='home'>
  {/* <div className="blur hero-blur"></div> */}
 <div className="left-h">

 <Header/>

 {/* the best ad */}
 <div className="the-best-ad">
  <motion.div
initial={{left:'398px'}}
whileInView={{left:'18px'}}
transition={{...transition, type:'tween'}}
>
  </motion.div>
 <div></div>
 <span>the best disability support services in the country</span>
 </div>



 {/* the hero text */}
 {/* <div className="blur hero-blur"></div> */}
 <div className="hero-text">
 <div className="blur hero-blur"></div>
  <div>
    <span className='stroke-text'>Empowering</span>
    <span> Abilities</span>
  </div>
  <div>
    <span>Across Australia</span>
  </div>
 </div>
 <div style={{color:'white'}}>
    <span>
      In here we will embrace all individuals regardless
      of physical abilities, mental health or personality traits.
    </span>
 </div>


 {/* <div style={{display:'flex', gap:'2rem', flexDirection:'column'}}>
  <div style={{display:'flex', flexDirection:'row', fontSize:'2rem', color:'white'}}>
    <span>+123</span>
    <span style={{marginLeft:'1rem', fontSize:'1rem', color:'var(--gray)', textTransform:'uppercase'}}>expert nurses</span>
  </div>
</div>

<div style={{display:'flex', gap:'2rem', flexDirection:'column'}}> 
  <div style={{display:'flex', flexDirection:'row', fontSize:'2rem', color:'white'}}>
    <span>+234</span>
    <span style={{fontSize:'1rem', color:'var(--gray)', textTransform:'uppercase'}}>members joined</span>
  </div>
</div>

<div style={{display:'flex', gap:'2rem', flexDirection:'column'}}>
  <div style={{display:'flex', flexDirection:'column', fontSize:'2rem', color:'white'}}>
    <span style={{transform:'translate(25%,-365%)'}}>+10</span>
    <span style={{fontSize:'1rem', color:'var(--gray)', textTransform:'uppercase', transform:'translate(25%,-315%)'}}>programs</span>
  </div>
</div> */}

{/* hero buttons */}
<div className="hero-buttons">
  <button className='btn'>Get Started</button>
  <button className='btn'>Learn More</button>
  
</div>

</div>
 <div className="right-h">
  <btn className='btn'>Join Now</btn>

  {/* hero images */}
<img src={hero_image} alt="" className='hero-image'></img>
<motion.img 
initial={{right:'16rem'}}
whileInView={{right:'10rem'}}
// transition={{transition}}
transition={{...transition, type:'tween', duration:2}}
src={hero_image_back} alt="" className='hero-image-back'></motion.img>



 </div>
</div>
  )
}

export default Hero